<script setup lang="ts">
import type { AtomsShortcutProps } from './AtomsShortcut.props'

withDefaults(defineProps<AtomsShortcutProps>(), {
  size: 'L',
  orientation: 'vertical',
  bgColor: 'white',
  isUppercase: false,
  underline: false,
})
const emit = defineEmits<{
  (e: 'link-click'): void
}>()
</script>

<template>
  <NuxtLink
    :to="link"
    active-class="!bg-neutral-white"
    class="text-book-7"
    :class="[
      { 'border-neutral-black border-b md:border-b-0': underline },
      bgColor === 'white' ? 'bg-neutral-white' : 'bg-primitives-off-white',
    ]"
    @click="emit('link-click')"
  >
    <div
      class="flex items-center justify-center overflow-hidden px-2"
      :class="[
        { 'md:h-22 h-16 gap-1 md:gap-2': size === 'M' },
        orientation === 'vertical' ? 'flex-col' : 'flex-row',
        size === 'L' ? 'h-22 gap-2' : 'h-16 gap-1',
      ]"
    >
      <div v-if="$slots.icon" class="h-6 w-6">
        <slot name="icon" />
      </div>
      <div
        v-if="$slots.text"
        class="line-clamp-1"
        :class="[isUppercase && 'uppercase']"
      >
        <slot name="text" />
      </div>
    </div>
  </NuxtLink>
</template>
